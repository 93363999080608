import { apiClient } from './AbstractHTTPService'
import FileSaver from 'file-saver';

const PATH = '/file/'

export default {
  getCategories() {
    return apiClient.get('/basedata/filecategory/')
  },

  download(data) {
    apiClient({
      method: 'GET',
      url: PATH + data.id + '/',
      responseType: 'blob'
    })
      .then(response => {
        var arrayPromise = new Promise(function (resolve) {
          var reader = new FileReader();

          reader.onloadend = function () {
            resolve(reader.result);
          };

          reader.readAsArrayBuffer(response.data);
        });

        arrayPromise.then(function (array) {
       //   const base64String = btoa(String.fromCharCode.apply(null, new Uint8Array(array)));
      //    console.log('data:image/jpg;base64,' + base64String);
        });

        var FileSaver = require('file-saver');
        var blob = new Blob([response.data], { type: response.headers['content-type'] })
        FileSaver.saveAs(blob, data.sFilename);
      })
      .catch((e) => console.log(e))
  },
  downloadSpecial(string, absolutePath = false) {
    apiClient({
      method: 'GET',
      url: (absolutePath) ? string : PATH + string + '/',
      responseType: 'blob'
    })
      .then(response => {
        var arrayPromise = new Promise(function (resolve) {
          var reader = new FileReader();

          reader.onloadend = function () {
            resolve(reader.result);
          };

          reader.readAsArrayBuffer(response.data);
        });

        arrayPromise.then(function (array) {
          const base64String = btoa(String.fromCharCode.apply(null, new Uint8Array(array)));
          console.log('data:image/jpg;base64,' + base64String);
        });

        var blob = new Blob([response.data], { type: response.headers['content-type'] })

        var fileName = response.headers['x-filename'];
        if (fileName == undefined && response.headers['content-disposition'] != undefined) {
          fileName = response.headers['content-disposition'].split('filename=')[1].split(';')[0];
          if (fileName.toLowerCase().startsWith('utf-8\'\'')) {
            fileName = decodeURIComponent(fileName.replace('utf-8\'\'', ''))
          } else {
            fileName = fileName.replace(/['"]/g, '')
          }
        }

        FileSaver.saveAs(blob, fileName);
      })
      .catch((e) => console.log(e))
  }
}
